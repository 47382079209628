import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Container from "../components/Container"
import SEO from "../components/SEO"
// import { Link } from "gatsby"
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Gallery from "../components/Work/Gallery"

class IndividualWorkTemplate extends React.Component {
  render() {
    const work = this.props.data.contentfulWork
    const projectImages = work.projectImages
    // const summary = work.summary

    return (
      <Layout>
        <SEO title={`${work.title}`} />

        <Container>
          <div className="heading">
            {/* <Link to="/">View other work</Link> */}
            <h1>{work.title}</h1>
          </div>
          <h2 className="subheading">{work.subheading}</h2>
          <Gallery images={projectImages} />
          {/* <div className="content">
            {summary ? documentToReactComponents(summary.json) : ``}
          </div> */}
        </Container>
      </Layout>
    )
  }
}

export default IndividualWorkTemplate

export const pageQuery = graphql`
  query WorkBySlug($slug: String!) {
    contentfulWork(slug: { eq: $slug }) {
      title
      subheading
      slug
      featuredImage {
        fluid(maxWidth: 900) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      projectImages {
        id
        fluid(maxWidth: 1180) {
          src
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
        title
      }
    }
  }
`
