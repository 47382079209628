import React, { Component } from "react"
import Lightbox from "react-image-lightbox"
// import "react-image-lightbox/style.css";
import Img from "gatsby-image"
import "../../assets/scss/react-image-lightbox.scss"

let images = []

class Gallery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  componentDidMount() {
    images = []
  }

  render() {
    const { photoIndex, isOpen } = this.state
    const projectImages = this.props.images
  
    return (
      <section id="work-gallery">
        <div className="work-grid">
          {projectImages
            ? projectImages.map((image, index) => {
                let imageMeta = {
                  title: image.title,
                  desc: image.description,
                  src: image.fluid.src,
                  placeholder: image.fluid.base64,
                }
                images.push(imageMeta)
                return (
                  <div className="tile tile__gallery" key={image.id}>
                    <a
                      tabIndex="0"
                      role="button"
                      onKeyPress={() =>
                        this.setState({ isOpen: true, photoIndex: index })
                      }
                      onClick={() =>
                        this.setState({ isOpen: true, photoIndex: index })
                      }
                    >
                      {/* <Img fluid={image.fluid} alt={image.title} /> */}
                      <Img
                        alt={image.title}
                        fluid={{ ...image.fluid, aspectRatio: 1 }}
                        backgroundColor={'#f9f9f9'}
                      />
                    </a>
                  </div>
                )
              })
            : ``}
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            // mainSrcThumbnail={images[photoIndex].placeholder}
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length].src
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
            imageTitle={images[photoIndex].title}
            imageCaption={images[photoIndex].desc}
            enableZoom={false}
            imagePadding={50}
          />
        )}
      </section>
    )
  }
}
export default Gallery
